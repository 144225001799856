.header__inner .header__inner,
.header__inner .header__left {
  display: block;
}

.header__inner .header__inner > a,
.header__inner .header__inner > div,
.header__inner .header__left > a,
.header__inner .header__left > div {
  float: left;
}

.header__inner .header__left {
  clear: both;
}

.header__inner .header__region {
  display: block;
}

.header__inner .header__region > div {
  float: left;
}

@media all and (min-width: 768px) {
  .header__inner .header__region div.header-menu {
    width: 60%;
  }
}

@media all and (min-width: 980px) {
  .header__inner .header__region div.header-menu {
    width: 65%;
  }
}

@media all and (min-width: 1200px) {
  .header__inner .header__region div.header-menu {
    width: 70%;
  }
  .large-fonts .header__inner .header__region div.header-menu {
    width: 67%;
  }
}

@media all and (min-width: 768px) {
  .header__inner .header__region #block-search-api-page-default-search {
    width: 25%;
  }
}

@media all and (min-width: 980px) {
  .header__inner .header__region #block-search-api-page-default-search {
    width: 20%;
  }
}

@media all and (min-width: 768px) {
  .header__inner .header__region #block-agov-text-resize-text-resize {
    width: 10%;
  }
}

@media all and (min-width: 980px) {
  .header__inner .header__region #block-agov-text-resize-text-resize {
    width: 7%;
  }
  .large-fonts .header__inner .header__region #block-agov-text-resize-text-resize {
    width: 9%;
  }
}

.header__inner .header__name-and-slogan {
  display: block;
  margin-top: 2em;
}

.large-fonts .header__inner .header__name-and-slogan {
  margin-top: 1.5em;
}

.view-front-slideshow .front-slide {
  display: block;
}

.front-slide-inner {
  display: table;
  height: 100%;
}

.front-slide-inner .inner-2 {
  display: table-cell;
  vertical-align: middle;
}

.view-second-row .view-content {
  display: block;
  overflow: hidden;
}

.view-second-row .view-content > div {
  float: left;
  width: 25%;
}

.front #content-middle {
  display: block;
  overflow: hidden;
}

.front #content-middle > div {
  width: 50%;
  float: left;
}

.front #content-middle .region-content-bottom {
  width: 100%;
}

.front #content-middle .region-content-middle-second {
  display: block;
  overflow: hidden;
}

.front #content-middle .region-content-middle-second > div {
  width: 50%;
  float: left;
}

.front .region-bottom-first {
  display: block;
  overflow: hidden;
}

.front .region-bottom-first > div {
  width: 75%;
  float: left;
}

.front .region-bottom-first #block-views-events-block-6 {
  width: 25%;
}

#footer-top .menu-block-wrapper > ul {
  display: block;
  overflow: hidden;
}

#footer-top .menu-block-wrapper > ul > li {
  width: 16.66%;
  float: left;
}

.region-footer-second {
  display: block;
  overflow: hidden;
}

.region-footer-second #block-menu-menu-footer-contact {
  float: right;
  width: 33.32%;
}

.region-footer-second #block-qmhc-footer-logo {
  width: 18%;
  float: left;
}

.region-footer-second #block-qmhc-footer-address {
  width: 50%;
  float: left;
}

.region-footer-second #block-qmhc-footer-social-links {
  float: left;
}

#block-views-latest-news-block-1 .view-content {
  display: block;
  overflow: hidden;
}

#block-views-latest-news-block-1 .view-content > div {
  float: left;
  width: 25%;
}

.region-bottom-second,
.front .view-bulletin-board-stories .view-content {
  display: block;
  overflow: hidden;
}

.region-bottom-second > div,
.front .view-bulletin-board-stories .view-content > div {
  float: left;
  width: 33.3%;
}

.front #content-middle .region-content-middle-first {
  display: block;
  overflow: hidden;
}

.front #content-middle .region-content-middle-first > div .block-inner {
  display: block;
  overflow: hidden;
  margin-bottom: 1em;
}

#block-views-explore-jump-menu-block .block-inner h3 {
  float: left;
  width: 50%;
}

#block-views-explore-jump-menu-block .block-inner .view {
  float: left;
  width: 50%;
}

#block-addthis-addthis-block {
  display: none;
}

#block-views-videos-block .u-faux-block-link {
  display: block;
  overflow: hidden;
}

#block-views-videos-block .u-faux-block-link .field-feature-video {
  float: left;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol {
  display: block;
  overflow: hidden;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol > li {
  float: left;
  height: 14em;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol > li#menu-1501-1 {
  border-bottom: none;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol > li#menu-1502-1 {
  height: 3em;
}

#block-menu-menu-help-menu .block-inner ul.menu li {
  display: table;
  width: 100%;
}

#block-menu-menu-help-menu .block-inner ul.menu li a {
  display: table-cell;
}

#block-menu-menu-help-menu .block-inner ul.menu li a img {
  vertical-align: middle;
}

.view-child-pages .views-row {
  display: block;
  overflow: hidden;
}

.view-child-pages .views-row .teaser-icon-left {
  float: left;
}

.region-footer-second {
  display: block;
  overflow: hidden;
}

@media all and (min-width: 768px) {
  .region-footer-second #block-menu-menu-footer-contact {
    float: right;
    width: 33.32%;
  }
  .region-footer-second #block-qmhc-footer-logo {
    width: 7%;
    float: left;
  }
  .region-footer-second #block-qmhc-footer-address {
    width: 43%;
    float: left;
    display: table;
    height: 9em;
  }
  .large-fonts .region-footer-second #block-qmhc-footer-address {
    height: 7em;
  }
  .region-footer-second #block-qmhc-footer-address .block-inner {
    display: table-cell;
    vertical-align: middle;
  }
  .region-footer-second #block-qmhc-footer-social-links {
    float: left;
  }
}

#block-block-3 .block__content,
#block-qmhc-subscribe .block__content {
  display: block !important;
  overflow: hidden;
}

#block-block-3 .block__content form > div,
#block-qmhc-subscribe .block__content form > div {
  display: block !important;
  overflow: hidden;
  margin: 0.5em 0 0;
}

#block-block-3 .block__content p,
#block-qmhc-subscribe .block__content p {
  float: left;
  width: 50%;
}

#block-block-3 .block__content form,
#block-qmhc-subscribe .block__content form {
  float: left;
  width: 50%;
}

#block-block-3 .block__content form .form-type-emailfield,
#block-qmhc-subscribe .block__content form .form-type-emailfield {
  display: inline;
}

#block-block-3 .block__content form input.form-text,
#block-qmhc-subscribe .block__content form input.form-text {
  width: 74%;
}

@media all and (min-width: 980px) {
  #block-block-3 .block__content form input.form-text,
  #block-qmhc-subscribe .block__content form input.form-text {
    width: 79%;
  }
}

.view-child-pages .views-row {
  display: table;
}

.view-child-pages .views-row .teaser-icon-right {
  display: table-cell;
  vertical-align: top;
}

.view-child-pages .views-row .field-teaser-icon {
  display: table-cell;
  vertical-align: top;
  width: 70px;
  padding-right: 1em;
}

.region-footer-second {
  display: block;
  overflow: hidden;
}

@media all and (max-width: 767px) {
  .region-footer-second #block-menu-menu-footer-contact {
    float: left;
  }
  .region-footer-second #block-qmhc-footer-logo {
    width: 17%;
    float: left;
    clear: left;
  }
  .region-footer-second #block-qmhc-footer-address {
    float: left;
    width: 40%;
  }
  .region-footer-second #block-qmhc-footer-social-links {
    float: left;
    position: relative;
    top: -24px;
  }
}

@media all and (min-width: 768px) {
  .region-footer-second #block-menu-menu-footer-contact {
    float: right;
    width: 33.32%;
  }
  .region-footer-second #block-qmhc-footer-logo {
    width: 7%;
    float: left;
  }
  .region-footer-second #block-qmhc-footer-address {
    width: 43%;
    float: left;
    display: table;
    height: 9em;
  }
  .large-fonts .region-footer-second #block-qmhc-footer-address {
    height: 7em;
  }
  .region-footer-second #block-qmhc-footer-address .block-inner {
    display: table-cell;
    vertical-align: middle;
  }
  .region-footer-second #block-qmhc-footer-social-links {
    float: left;
    position: static;
  }
}
