@mixin ie-display-block {
  display: block;
  overflow: hidden;
}

.header__inner {
  .header__inner,
  .header__left {
    display: block;

    > a,
    > div {
      float: left;
    }
  }
  .header__left {
    clear: both;
  }
  .header__region {
     display: block;

     > div {
      float: left;
     }
    div.header-menu {
      @media all and (min-width: 768px) {
      width: 60%;
    }
      @media all and (min-width: 980px) {
         width: 65%;
      }
       @media all and (min-width: 1200px) {
        width: 70%;

          .large-fonts & {
          width: 67%;
         }
      }
    }
    #block-search-api-page-default-search {
       @media all and (min-width: 768px) {
      width: 25%;
    }
      @media all and (min-width: 980px) {
      width: 20%;
      }
    }
    #block-agov-text-resize-text-resize {
       @media all and (min-width: 768px) {
      width: 10%;
    }
       @media all and (min-width: 980px) {
      width: 7%;

        .large-fonts & {
          width: 9%;
         }
      }
    }
  }
  .header__name-and-slogan {
    display: block;
    margin-top: 2em;

    .large-fonts & {
      margin-top: 1.5em;
    }
  }
}

.view-front-slideshow .front-slide {
  display: block;
}
.front-slide-inner {
  display: table;
  height: 100%;

  .inner-2 {
    display: table-cell;
    vertical-align: middle;
  }
}

.view-second-row .view-content {
  @include ie-display-block();

   > div {
    float: left;
    width: 25%;
   }
}

.front #content-middle {
  @include ie-display-block();

  > div {
    width: 50%;
    float: left;
   }
   .region-content-bottom {
    width: 100%;
   }
}

.front #content-middle .region-content-middle-second {
  @include ie-display-block();

   > div {
    width: 50%;
    float: left;
  }
}

.front .region-bottom-first {
  @include ie-display-block();

  > div {
    width: 75%;
    float: left;
  }
  #block-views-events-block-6 {
    width: 25%;
  }
}

#footer-top .menu-block-wrapper > ul {
  @include ie-display-block();

  > li {
    width: 16.66%;
    float: left;
  }
}

.region-footer-second {
  @include ie-display-block();

  #block-menu-menu-footer-contact {
    float: right;
    width: 33.32%;
  }
  #block-qmhc-footer-logo {
    width: 18%;
    float: left;
  }
  #block-qmhc-footer-address {
    width: 50%;
    float: left;
  }
  #block-qmhc-footer-social-links {
    float: left;
  }
}

#block-views-latest-news-block-1 {
  .view-content {
    @include ie-display-block();

      > div {
      float: left;
      width: 25%;
    }
  }
}

.region-bottom-second,
.front .view-bulletin-board-stories .view-content {
   @include ie-display-block();

   > div {
    float: left;
    width: 33.3%;
  }
}

.front #content-middle .region-content-middle-first {
  @include ie-display-block();

  > div .block-inner {
    @include ie-display-block();
    margin-bottom: 1em;
  }
}

#block-views-explore-jump-menu-block .block-inner {
  h3 {
    float: left;
    width: 50%;
  }
  .view {
    float: left;
    width: 50%;
  }
}

#block-addthis-addthis-block {
  display: none;
}

#block-views-videos-block .u-faux-block-link {
  @include ie-display-block();

  .field-feature-video {
    float: left;
  }
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol {
  @include ie-display-block();

  > li {
    float: left;
    height: 14em;

     &#menu-1501-1 {
      border-bottom: none;
     }
     &#menu-1502-1 {
      height: 3em;
     }
  }
}

#block-menu-menu-help-menu .block-inner ul.menu li {
  display: table;
  width: 100%;

  a {
    display: table-cell;

    img {
      vertical-align: middle;
    }
  }
}

.view-child-pages .views-row {
   @include ie-display-block();
  .teaser-icon-left {
    float: left;
  }
}

.region-footer-second {
 display: block;
 overflow: hidden;

 @media all and (min-width: 768px) {
  #block-menu-menu-footer-contact {
    float: right;
    width: 33.32%;
  }
  #block-qmhc-footer-logo {
    width: 7%;
    float: left;
  }
  #block-qmhc-footer-address {
    width: 43%;
    float: left;
    display: table;
    height: 9em;

     .large-fonts & {
      height: 7em;
     }

    .block-inner {
      display: table-cell;
      vertical-align: middle;
    }
  }
  #block-qmhc-footer-social-links {
    float: left;
  }
 }
}

#block-block-3 .block__content,
#block-qmhc-subscribe .block__content {
  display: block !important;
  overflow: hidden;

  form > div {
    display: block !important;
    overflow: hidden;
    margin: 0.5em 0 0;
  }
  p {
    float: left;
    width: 50%;
  }
  form {
    float: left;
    width: 50%;

    .form-type-emailfield {
      display: inline;
    }

     input.form-text {
      width: 74%;

      @media all and (min-width: 980px) {
        width: 79%;
      }
     }
  }
}

.view-child-pages {
  .views-row {
    display: table;
     .teaser-icon-right {
      display: table-cell;
      vertical-align: top;
     }
     .field-teaser-icon {
      display: table-cell;
      vertical-align: top;
     width: 70px;
      padding-right: 1em;
     }
   }
}


.region-footer-second {
 display: block;
 overflow: hidden;

 @media all and (max-width: 767px) {

  #block-menu-menu-footer-contact {
     float: left;
    }
  #block-qmhc-footer-logo {
    width: 17%;
    float: left;
    clear: left;
  }
  #block-qmhc-footer-address {
    float: left;
    width: 40%;
  }
    #block-qmhc-footer-social-links {
    float: left;
    position: relative;
    top: -24px;
  }
}

 @media all and (min-width: 768px) {
  #block-menu-menu-footer-contact {
    float: right;
    width: 33.32%;
  }
  #block-qmhc-footer-logo {
    width: 7%;
    float: left;
  }
  #block-qmhc-footer-address {
    width: 43%;
    float: left;
    display: table;
    height: 9em;

     .large-fonts & {
      height: 7em;
     }

    .block-inner {
      display: table-cell;
      vertical-align: middle;
    }
  }
  #block-qmhc-footer-social-links {
    float: left;
    position: static;
  }
 }
}